import LocomotiveScroll from "locomotive-scroll";

class LocomotiveScrollCtrl {
  constructor() {
    this.lmScroll = undefined;
    this.tweeks = {
      smooth: false,
      multiplier: 1,
    };
    this.gui = undefined;
    this.images = [];
    this._onImageLoaded = this.onImageLoaded.bind(this);
  }

  onImageLoaded() {
    if (this.lmScroll) {
      this.lmScroll.update();
    }
  }
  // onChangeSmoothAddListener(_cb) {
  //   this.onChangeSmoothCbs.push(_cb);
  // }

  // onChangeSmoothRemoveListener(_cb) {
  //   let iCb = undefined;
  //   for (let i = 0, j = this.onChangeSmoothCbs.length; i < j; i++) {
  //     const cb = this.onChangeSmoothCbs[i];
  //     if (cb === _cb) {
  //       iCb = i;
  //       break;
  //     }
  //   }
  //   if (typeof iCb === "number" && this.onChangeSmoothCbs(iCb)) {
  //     this.onChangeSmoothCbs.splice(iCb, 1);
  //   }
  // }

  destroyScroll() {
    this.images.forEach((img) => {
      img.removeEventListener("load", this._onImageLoaded);
    });
    this.images = [];
    if (this.lmScroll) {
      this.lmScroll.destroy();
      this.lmScroll = undefined;
    }
  }

  createScroll(onCallCb) {
    this.destroyScroll();
    const el = document.querySelector("[data-scroll-container]");
    this.lmScroll = new LocomotiveScroll({
      el: el,
      smooth: this.tweeks.smooth,
      multiplier: this.tweeks.multiplier,
    });
    this.lmScroll.on("call", (callId, status) => {
      const el = document.querySelector(`[data-scroll-call="${callId}"]`);
      if (typeof onCallCb === "function") {
        onCallCb(callId, status, el);
      }
    });
    if (!this.tweeks.smooth) {
      el.style = "";
    }
    this.images = document.querySelectorAll("img");
    this.images.forEach((img) => {
      img.addEventListener("load", this._onImageLoaded);
    });
    this.lmScroll.update();
  }
  prepareTweeks() {
    this.gui.add(this.tweeks, "smooth").onChange(this.createScroll.bind(this));
    this.gui
      .add(this.tweeks, "multiplier")
      .min(0)
      .max(10)
      .step(0.001)
      .onFinishChange(this.createScroll.bind(this));
  }
}

export default LocomotiveScrollCtrl;
