import Zdog from "zdog";

class ZdogCrl {
  constructor() {
    this.thinkZdogIcon = undefined;
    this.designZdogIcon = undefined;
    this.developZdogIcon = undefined;
    this.developZdogIconAnchor = undefined;
    this.ticker = 0;
    this.cycleCount = 1000;
    this.rafId = undefined;
    this.blue = "#00dbff";
    this.white = "#FFF";
    this.black = "#000";
    this.wStroke = 8;
    this.distanceFromCenter = 45;
    this.inView = false;
  }
  createThinkIcon(selector = ".illo-think") {
    const container = document.querySelector(selector);
    if (!container) {
      return null;
    }
    const illo = new Zdog.Illustration({
      element: selector,
      dragRotate: true,
      resize: true,
      zoom: 0.6,
    });

    new Zdog.Rect({
      addTo: illo,
      width: 20,
      height: 20,
      stroke: this.wStroke,
      color: this.blue,
      rotate: { z: -Zdog.TAU / 8 },
    });

    new Zdog.Shape({
      addTo: illo,
      path: [
        { x: 0, y: 0 },
        { x: -20, y: 20 },
        { x: 0, y: 40 },
      ],
      translate: { x: -40, y: -20 },
      closed: false,
      stroke: this.wStroke,
      color: this.black,
    });

    new Zdog.Shape({
      addTo: illo,
      path: [
        { x: 0, y: 0 },
        { x: 20, y: 20 },
        { x: 0, y: 40 },
      ],
      translate: { x: 40, y: -20 },
      closed: false,
      stroke: this.wStroke,
      color: this.black,
    });

    new Zdog.Shape({
      addTo: illo,
      path: [
        { x: 0, y: 0 },
        { x: 20, y: 20 },
        { x: 40, y: 0 },
      ],
      translate: { x: -20, y: 40 },
      closed: false,
      stroke: this.wStroke,
      color: this.black,
    });

    new Zdog.Shape({
      addTo: illo,
      path: [
        { x: 0, y: 0 },
        { x: 20, y: -20 },
        { x: 40, y: 0 },
      ],
      translate: { x: -20, y: -40 },
      closed: false,
      stroke: this.wStroke,
      color: this.black,
    });

    this.thinkZdogIcon = illo;
    this.thinkZdogIcon.updateRenderGraph();
  }

  createDesignIcon(selector = ".illo-design") {
    const container = document.querySelector(selector);
    if (!container) {
      return null;
    }

    const illo = new Zdog.Illustration({
      element: selector,
      zoom: 0.5,
      dragRotate: true,
      resize: true,
      rotate: { x: 1, z: Zdog.TAU / 8 },
    });
    new Zdog.Shape({
      addTo: illo,
      // no path set, default to single point
      stroke: 40,
      color: this.blue,
    });

    const anchorFaceFront = new Zdog.Anchor({
      addTo: illo,
      translate: { z: this.distanceFromCenter },
    });

    const anchorFaceBack = new Zdog.Anchor({
      addTo: illo,
      translate: { z: -this.distanceFromCenter },
    });

    const anchorFaceTop = new Zdog.Anchor({
      addTo: illo,
      translate: { y: this.distanceFromCenter },
    });

    const anchorFaceBottom = new Zdog.Anchor({
      addTo: illo,
      translate: { y: -this.distanceFromCenter },
    });

    const anchorFaceLeft = new Zdog.Anchor({
      addTo: illo,
      translate: { x: this.distanceFromCenter },
    });

    const anchorFaceRight = new Zdog.Anchor({
      addTo: illo,
      translate: { x: -this.distanceFromCenter },
    });

    const face = {
      addTo: anchorFaceFront,
      width: 90,
      height: 90,
      stroke: this.wStroke,
      color: this.black,
    };

    new Zdog.Rect({ ...face, addTo: anchorFaceFront });

    new Zdog.Rect({ ...face, addTo: anchorFaceBack, color: this.grey });

    new Zdog.Rect({
      ...face,
      addTo: anchorFaceTop,
      rotate: { x: Zdog.TAU / 4 },
    });

    new Zdog.Rect({
      ...face,
      addTo: anchorFaceLeft,
      rotate: { y: Zdog.TAU / 4 },
    });

    new Zdog.Rect({
      ...face,
      addTo: anchorFaceRight,
      rotate: { y: Zdog.TAU / 4 },
      color: this.grey,
    });

    this.designZdogIcon = illo;
    this.designZdogIcon.updateRenderGraph();
  }

  createDevelopIcon(selector = ".illo-develop") {
    const container = document.querySelector(selector);
    if (!container) {
      return null;
    }

    const illo = new Zdog.Illustration({
      element: selector,
      zoom: 8,
      dragRotate: true,
      resize: true,
    });

    const anchorFaceFront = new Zdog.Anchor({
      addTo: illo,
    });

    const a = 0.0;
    const b = 1.0;
    const c = 2.0;
    const d = (1.0 + Math.sqrt(5.0)) / 2.0;
    const e = 3.0 * d;
    const f = 1.0 + 2.0 * d;
    const g = 2.0 + d;
    const h = 2.0 * d;

    const point = {
      addTo: anchorFaceFront,
      stroke: 0.5,
      color: this.black,
    };

    new Zdog.Shape({
      addTo: illo,
      stroke: 4,
      color: this.blue,
    });

    //PURPLE
    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -e, y: +a, z: -b },
        { x: -e, y: +a, z: +b },
        { x: -f, y: -d, z: +c },
        { x: -g, y: -h, z: +b },
        { x: -g, y: -h, z: -b },
        { x: -f, y: -d, z: -c },
        { x: -e, y: +a, z: -b },
        { x: -e, y: +a, z: -b },
        { x: -f, y: +d, z: -c },
        { x: -g, y: +h, z: -b },
        { x: -g, y: +h, z: +b },
        { x: -f, y: +d, z: +c },
        { x: -e, y: +a, z: +b },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -g, y: -h, z: +b },
        { x: -c, y: -f, z: +d },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -g, y: -h, z: -b },
        { x: -c, y: -f, z: -d },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -g, y: +h, z: +b },
        { x: -c, y: +f, z: +d },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -g, y: +h, z: -b },
        { x: -c, y: +f, z: -d },
      ],
    });

    //BROWN

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -b, y: +e, z: +a },
        { x: +b, y: +e, z: +a },
        { x: +c, y: +f, z: -d },
        { x: +b, y: +g, z: -h },
        { x: -b, y: +g, z: -h },
        { x: -c, y: +f, z: -d },
        { x: -b, y: +e, z: +a },
        { x: -c, y: +f, z: +d },
        { x: -b, y: +g, z: +h },
        { x: +b, y: +g, z: +h },
        { x: +c, y: +f, z: +d },
        { x: +b, y: +e, z: +a },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -b, y: +g, z: +h },
        { x: -d, y: +c, z: +f },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +b, y: +g, z: +h },
        { x: +d, y: +c, z: +f },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +b, y: +g, z: -h },
        { x: +d, y: +c, z: -f },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -b, y: +g, z: -h },
        { x: -d, y: +c, z: -f },
      ],
    });

    //YELLOW

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -b, y: -e, z: +a },
        { x: +b, y: -e, z: +a },
        { x: +c, y: -f, z: +d },
        { x: +b, y: -g, z: +h },
        { x: -b, y: -g, z: +h },
        { x: -c, y: -f, z: +d },
        { x: -b, y: -e, z: +a },
        { x: -c, y: -f, z: -d },
        { x: -b, y: -g, z: -h },
        { x: +b, y: -g, z: -h },
        { x: +c, y: -f, z: -d },
        { x: +b, y: -e, z: +a },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +b, y: -g, z: +h },
        { x: +d, y: -c, z: +f },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -b, y: -g, z: +h },
        { x: -d, y: -c, z: +f },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -b, y: -g, z: -h },
        { x: -d, y: -c, z: -f },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +b, y: -g, z: -h },
        { x: +d, y: -c, z: -f },
      ],
    });

    //GREEN

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +e, y: +a, z: +b },
        { x: +e, y: +a, z: -b },
        { x: +e, y: +a, z: -b },
        { x: +f, y: -d, z: -c },
        { x: +g, y: -h, z: -b },
        { x: +g, y: -h, z: +b },
        { x: +f, y: -d, z: +c },
        { x: +e, y: +a, z: +b },
        { x: +e, y: +a, z: +b },
        { x: +f, y: +d, z: +c },
        { x: +g, y: +h, z: +b },
        { x: +g, y: +h, z: -b },
        { x: +f, y: +d, z: -c },
        { x: +e, y: +a, z: -b },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +g, y: -h, z: -b },
        { x: +c, y: -f, z: -d },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +g, y: -h, z: +b },
        { x: +c, y: -f, z: +d },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +g, y: +h, z: -b },
        { x: +c, y: +f, z: -d },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +g, y: +h, z: +b },
        { x: +c, y: +f, z: +d },
      ],
    });

    //PINK

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +a, y: +b, z: -e },
        { x: +a, y: -b, z: -e },
        { x: +a, y: +b, z: -e },
        { x: +d, y: +c, z: -f },
        { x: +h, y: +b, z: -g },
        { x: +h, y: -b, z: -g },
        { x: +d, y: -c, z: -f },
        { x: +a, y: -b, z: -e },
        { x: +a, y: -b, z: -e },
        { x: -d, y: -c, z: -f },
        { x: -h, y: -b, z: -g },
        { x: -h, y: +b, z: -g },
        { x: -d, y: +c, z: -f },
        { x: +a, y: +b, z: -e },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +h, y: -b, z: -g },
        { x: +f, y: -d, z: -c },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +h, y: +b, z: -g },
        { x: +f, y: +d, z: -c },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -h, y: -b, z: -g },
        { x: -f, y: -d, z: -c },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -h, y: +b, z: -g },
        { x: -f, y: +d, z: -c },
      ],
    });

    //BLUE
    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +a, y: -b, z: +e },
        { x: +a, y: +b, z: +e },
        { x: +a, y: +b, z: +e },
        { x: -d, y: +c, z: +f },
        { x: -h, y: +b, z: +g },
        { x: -h, y: -b, z: +g },
        { x: -d, y: -c, z: +f },
        { x: +a, y: -b, z: +e },
        { x: +a, y: -b, z: +e },
        { x: +d, y: -c, z: +f },
        { x: +h, y: -b, z: +g },
        { x: +h, y: +b, z: +g },
        { x: +d, y: +c, z: +f },
        { x: +a, y: +b, z: +e },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -h, y: -b, z: +g },
        { x: -f, y: -d, z: +c },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: -h, y: +b, z: +g },
        { x: -f, y: +d, z: +c },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +h, y: -b, z: +g },
        { x: +f, y: -d, z: +c },
      ],
    });

    new Zdog.Shape({
      ...point,
      closed: false,
      path: [
        { x: +h, y: +b, z: +g },
        { x: +f, y: +d, z: +c },
      ],
    });

    this.developZdogIconAnchor = anchorFaceFront;
    this.developZdogIcon = illo;
    this.developZdogIcon.updateRenderGraph();
  }

  play(rafCtrl) {
    if (
      !this.thinkZdogIcon &&
      !this.designZdogIcon &&
      !this.developZdogIcon &&
      !this.developZdogIconAnchor
    ) {
      return;
    }
    // this.updateIcons();
    rafCtrl.handleCb(this.updateIcons.bind(this));
  }

  updateIcons() {
    if (!this.inView) {
      return;
    }
    /*
    OPT 1
    this.thinkZdogIcon.rotate.x = window.scrollY / 100;
    this.thinkZdogIcon.rotate.y = window.scrollY / 200;
    this.designZdogIcon.rotate.x = window.scrollY / 100;
    this.designZdogIcon.rotate.y = window.scrollY / 200;
    this.developZdogIconAnchor.rotate.y = window.scrollY / 100;
    this.developZdogIconAnchor.rotate.x = window.scrollY / 200;

    this.thinkZdogIcon.updateRenderGraph();
    this.designZdogIcon.updateRenderGraph();
    this.developZdogIcon.updateRenderGraph();
    */

    /*
     **BASE
     */

    const progress = this.ticker / this.cycleCount;
    const tween = Zdog.easeInOut(progress % 1, 3);
    if (this.thinkZdogIcon) {
      this.thinkZdogIcon.rotate.y = tween * Zdog.TAU + window.scrollY / 500;
      this.thinkZdogIcon.updateRenderGraph();
    }
    if (this.designZdogIcon) {
      this.designZdogIcon.rotate.y = tween * Zdog.TAU + window.scrollY / 500;
      this.designZdogIcon.updateRenderGraph();
    }
    if (this.developZdogIconAnchor) {
      this.developZdogIconAnchor.rotate.y =
        tween * Zdog.TAU + window.scrollY / 500;
      this.developZdogIconAnchor.rotate.z =
        tween * Zdog.TAU + window.scrollY / 500;
      this.developZdogIcon.updateRenderGraph();
    }

    this.ticker++;

    // this.rafId = requestAnimationFrame(this.updateIcons.bind(this));
  }

  destroy() {
    // cancelAnimationFrame(this.rafId);
    this.inView = false;
    this.thinkZdogIcon = undefined;
    this.designZdogIcon = undefined;
    this.developZdogIcon = undefined;
    this.developZdogIconAnchor = undefined;
  }
}

export default ZdogCrl;
