import Swup from "swup";
import SwupGaPlugin from "@swup/ga-plugin";

class SwupCtrl {
  constructor() {
    this.swup = new Swup({
      plugins: [
        new SwupGaPlugin({
          gaMeasurementId: "UA-16824308-2",
        }),
      ],
    });
  }
  onContentReplaced(cb) {
    document.addEventListener("swup:contentReplaced", cb);
  }
  onWillReplaceContent(cb) {
    document.addEventListener("swup:willReplaceContent", cb);
  }
}

export default SwupCtrl;
