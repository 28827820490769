class CookiesBannerCtrl {
  launch() {
    const banner = document.querySelector(".rgpd");
    const acceptBtn = banner.querySelector(".aceptar");
    acceptBtn.addEventListener("click", () => {
      banner.classList.add("accepted");
    });
  }
}

export default CookiesBannerCtrl;
