class RevealVideoHoverCtrl {
  constructor() {
    this.projectCardElements = [];
  }
  launch(selector = ".highlighted-project-card") {
    const projectCardElements = document.querySelectorAll(selector);
    if (projectCardElements.length === 0) {
      return;
    }
    this.projectCardElements = projectCardElements;
    for (let i = 0, j = projectCardElements.length; i < j; i++) {
      const projectCardElement = projectCardElements[i];
      projectCardElement.addEventListener("mouseenter", this.handleMouseEnter);
      projectCardElement.addEventListener("mouseleave", this.handleMouseLeave);
    }
  }

  destroy() {
    for (let i = 0, j = this.projectCardElements.length; i < j; i++) {
      const projectCardElement = this.projectCardElements[i];
      projectCardElement.removeEventListener(
        "mouseenter",
        this.handleMouseEnter
      );
      projectCardElement.removeEventListener(
        "mouseleave",
        this.handleMouseLeave
      );
    }
    this.projectCardElements = [];
  }

  handleMouseEnter(e) {
    const videoElement = e.target.querySelector("video");
    if (!videoElement) {
      return;
    }
    videoElement.currentTime = 0;
    videoElement.play();
  }

  handleMouseLeave(e) {
    const videoElement = e.target.querySelector("video");
    if (!videoElement) {
      return;
    }
    videoElement.pause();
  }
}

export default RevealVideoHoverCtrl;
