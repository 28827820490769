import { CountUp } from "countup.js";

class CountUpCtrl {
  constructor() {
    this.countUps = [];
  }
  launch() {
    const countUpElements = document.querySelectorAll("[data-count-up]");
    for (let i = 0, j = countUpElements.length; i < j; i++) {
      const countUpElement = countUpElements[i];
      const num = Number(countUpElement.dataset.countUp);
      this.countUps.push(new CountUp(countUpElement, num, { duration: 8 }));
    }
  }
  destroy() {
    this.countUps = [];
  }
}

export default CountUpCtrl;
