import lottie from "lottie-web";

class LottieCtrl {
  constructor() {
    this.lotties = [];
  }
  launch() {
    const lottieElements = document.querySelectorAll("[data-lottie-animation]");
    if (lottieElements.length === 0) {
      return;
    }
    for (let i = 0, j = lottieElements.length; i < j; i++) {
      const lottieEl = lottieElements[i];
      const animationUrl = lottieEl.dataset.lottieAnimation;
      const lottiePlayer = lottie.loadAnimation({
        container: lottieEl,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: animationUrl,
      });
      this.lotties.push(lottiePlayer);
    }
  }
  destroy() {
    for (let i = 0, j = this.lotties.length; i < j; i++) {
      const lottiePlayer = this.lotties[i];
      lottiePlayer.pause();
      lottiePlayer.destroy();
    }
    this.lotties = [];
  }
}

export default LottieCtrl;
