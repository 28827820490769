class PaymentSwitchersCtrl {
  constructor() {
    this.switchers = [];
    this._onClickSwitcher = this.onClickSwitcher.bind(this);
  }
  launch() {
    this.switchers = document.querySelectorAll(".payment-switcher");
    if (this.switchers.length === 0) {
      return;
    }
    this.switchers.forEach((el) => {
      el.addEventListener("click", this._onClickSwitcher);
    });
  }
  onClickSwitcher(e) {
    e.preventDefault();
    const amount = e.target.dataset.amount;
    e.target
      .closest(".payment-switcher")
      .querySelectorAll(".switch-part")
      .forEach((el) => {
        el.classList.remove("active");
      });
    e.target.classList.add("active");
    e.target.closest(".tc-price").querySelector(".amount").innerHTML = amount;
  }
  destroy() {
    this.switchers.forEach((el) => {
      el.removeEventListener("click", this._onClickSwitcher);
    });
    this.switchers = [];
  }
}

export default PaymentSwitchersCtrl;
