class AccorionCtrl {
  constructor() {
    this.toggleBtns = [];
  }

  applyToggleListeners(selector = ".accordion-toggle-btn") {
    const accordionToggleBtns = document.querySelectorAll(selector);
    if (accordionToggleBtns.length === 0) {
      return;
    }
    this.toggleBtns = accordionToggleBtns;
    for (let i = 0, j = this.toggleBtns.length; i < j; i++) {
      const toggleBtn = this.toggleBtns[i];
      toggleBtn.addEventListener("click", this.bindToggleToBtn);
    }
  }

  removeCurrentToggleListeners() {
    for (let i = 0, j = this.toggleBtns.length; i < j; i++) {
      const toggleBtn = this.toggleBtns[i];
      toggleBtn.removeEventListener("click", this.bindToggleToBtn);
    }
    this.toggleBtns = [];
  }

  bindToggleToBtn(e) {
    e.preventDefault();
    e.target.classList.toggle("active");
    e.target
      .closest(".accordion-group")
      .querySelector(".accordion_wrapper")
      .classList.toggle("opened");
    window.setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }
}

export default AccorionCtrl;
