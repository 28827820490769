class DrawerCtrl {
  constructor() {
    this.drawer = undefined;
    this.drawerBg = undefined;
    this.drawerToggles = [];
    this._onClickToggler = this.onClickToggler.bind(this);
  }
  onClickToggler() {
    if (this.drawer.classList.contains("active")) {
      this.drawer.classList.remove("active");
      this.drawerBg.classList.remove("active");
      return;
    }
    this.drawer.classList.add("active");
    this.drawerBg.classList.add("active");
  }
  applyToggleListeners(selector = ".drawer-toggle") {
    const drawerToggles = document.querySelectorAll(selector);
    this.drawer = document.querySelector(".drawer");
    this.drawerBg = document.querySelector(".drawer-bg");
    for (let i = 0, j = drawerToggles.length; i < j; i++) {
      const drawerToggleBtn = drawerToggles[i];
      drawerToggleBtn.addEventListener("click", this._onClickToggler);
      this.drawerToggles.push(drawerToggleBtn);
    }
  }
  destroy() {
    for (let i = 0, j = this.drawerToggles.length; i < j; i++) {
      const drawerToggleBtn = this.drawerToggles[i];
      drawerToggleBtn.removeEventListener("click", this._onClickToggler);
    }
    this.drawerToggles = [];
  }
}

export default DrawerCtrl;
