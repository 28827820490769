class UpdateDateHistoryCtrl {
  getCurrentYear() {
    return new Date().getFullYear();
  }
  getDiffYears(initalYear = 2010, currentYear) {
    return currentYear - initalYear;
  }
  update() {
    const currentYearElement = document.querySelector(
      ".years-experience__current-year"
    );
    const diffYearsElement = document.querySelector(
      ".years-experience__diff-years"
    );
    if (!currentYearElement || !diffYearsElement) {
      return;
    }
    const currentYear = this.getCurrentYear();
    const diffYears = this.getDiffYears(2010, currentYear);
    currentYearElement.innerHTML = currentYear;
    diffYearsElement.innerHTML = diffYears;
  }
}

export default UpdateDateHistoryCtrl;
