import { lerp, getMousePos, distance } from "./utils";

let mousepos = { x: 0, y: 0 };
window.addEventListener("mousemove", (ev) => (mousepos = getMousePos(ev)));

class PlayReelButtonCtrl {
  constructor() {
    this.DOM = { el: undefined, icon: undefined };
    this.distanceToTriggerFactor = 2;
    this.iconDistanceFactor = 0.2;
    this.renderedStyles = {
      tx: { previous: 0, current: 0, amt: 0.1 },
      ty: { previous: 0, current: 0, amt: 0.1 },
    };
    this.lmscrollCtrl = undefined;
    this.state = {
      hover: false,
    };
    this.isActive = false;
    this.iframe = null;
    this.inView = false;
    this._onResizeWindow = this.onResizeWindow.bind(this);
  }

  onResizeWindow() {
    if (window.innerWidth >= 750) {
      this.isActive = true;
      return;
    }
    this.isActive = false;
  }

  launch(lmscrollCtrl, rafCtrl) {
    const el = document.querySelector(".reel-container__play-btn");
    if (!el) {
      return;
    }
    this.DOM = { el: el };
    this.lmscrollCtrl = lmscrollCtrl;
    this.rafID = undefined;
    this.calculateSizePosition();
    window.addEventListener("resize", this._onResizeWindow);
    this.onResizeWindow();
    rafCtrl.handleCb(this.render.bind(this));
  }
  calculateSizePosition() {
    this.rect = this.DOM.el.getBoundingClientRect();
    this.distanceToTrigger = this.rect.width * this.distanceToTriggerFactor;
  }

  render() {
    if (!this.inView || !this.isActive) {
      return;
    }
    const scrollX = 0;
    const scrollY = this.lmscrollCtrl.lmScroll.scroll.instance.scroll.y + 0;
    const distanceMouseButton = distance(
      mousepos.x + scrollX,
      mousepos.y + scrollY,
      this.rect.left + this.rect.width / 2,
      this.rect.top + this.rect.height / 2
    );

    let x = 0;
    let y = 0;

    if (distanceMouseButton < this.distanceToTrigger) {
      if (!this.state.hover) {
        this.enter();
      }
      x = (mousepos.x + scrollX - (this.rect.left + this.rect.width / 2)) * 0.3;
      y = (mousepos.y + scrollY - (this.rect.top + this.rect.height / 2)) * 0.3;
    } else if (this.state.hover) {
      this.leave();
    }

    this.renderedStyles["tx"].current = x;
    this.renderedStyles["ty"].current = y;

    for (const key in this.renderedStyles) {
      this.renderedStyles[key].previous = lerp(
        this.renderedStyles[key].previous,
        this.renderedStyles[key].current,
        this.renderedStyles[key].amt
      );
    }

    this.DOM.el.style.transform = `translate3d(${this.renderedStyles["tx"].previous}px, ${this.renderedStyles["ty"].previous}px, 0)`;
  }
  enter() {
    this.state.hover = true;
    this.DOM.el.classList.add("button--hover");
  }
  leave() {
    this.state.hover = false;
    this.DOM.el.classList.remove("button--hover");
  }
  destroy() {
    window.removeEventListener("resize", this._onResizeWindow);
    this.inView = false;
  }
}

export default PlayReelButtonCtrl;
