import "iframe-lightbox";

class LightBoxCtrl {
  constructor() {
    this.lightGallery = undefined;
    this.btn = undefined;
    this._onClickBtn = this.onClickBtn.bind(this);
  }

  onClickBtn(e) {
    if (this.lightGallery) {
      e.preventDefault();
      this.lightGallery.open();
    }
  }

  launch() {
    const lightGalleryElement = document.querySelector(
      "[data-light-box-gallery]"
    );
    if (!lightGalleryElement) {
      return;
    }
    this.btn = lightGalleryElement;
    this.btn.addEventListener("click", this._onClickBtn);
    this.lightGallery = new IframeLightbox(lightGalleryElement);
  }

  destroy() {
    if (this.btn) {
      this.btn.removeEventListener("click", this._onClickBtn);
    }
  }
}

export default LightBoxCtrl;
