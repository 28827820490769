import "./SCSS/index.scss";
import RafCtrl from "./scripts/RafCtrl";
import AccordionCtrl from "./scripts/AccordionCtrl";
import DrawerCtrl from "./scripts/DrawerCtrl";
import SwupCtrl from "./scripts/SwupCtrl";
import RevealImageHoverCtrl from "./scripts/RevealImageHoverCtrl";
import RevealVideoHoverCtrl from "./scripts/RevealVideoHoverCtrl";
import IntroLoader from "./scripts/IntroLoader";
import ZdogCtrl from "./scripts/ZdogCtrl";
import UpdateDateHistoryCtrl from "./scripts/UpdateDateHistoryCtrl";
import PlayReelButtonCtrl from "./scripts/PlayReelButtonCtrl";
import LottieCtrl from "./scripts/LottieCtrl";
import MouseFollower from "./scripts/MouseFollower";
import MenuCtrl from "./scripts/MenuCtrl";
import LocomotiveScrollCtrl from "./scripts/LocomotiveScrollCtrl";
import CountUpCtrl from "./scripts/CountUpCtrl";
import LightBoxCtrl from "./scripts/LightBoxCtrl";
import CookiesBannerCtrl from "./scripts/CookiesBannerCtrl";
import FilterYearCtrl from "./scripts/FilterYearCtrl";
import PaymentSwitchersCtrl from "./scripts/PaymentSwitchersCtrl";

const introLoader = new IntroLoader();
introLoader.launch();

window.addEventListener("load", () => {
  const rafCtrl = new RafCtrl();
  rafCtrl.launch();

  const cookiesBannerCtrl = new CookiesBannerCtrl();
  cookiesBannerCtrl.launch();

  const filterYearCtrl = new FilterYearCtrl();
  filterYearCtrl.launch();

  const onCallScroll = (callId, status, el) => {
    if (el.classList.contains("appear-animation")) {
      el.classList.add("appear-animation-in");
    }

    if (el.dataset.countUp) {
      for (let i = 0, j = countUpCtrl.countUps.length; i < j; i++) {
        const countUp = countUpCtrl.countUps[i];
        if (countUp.target === el) {
          countUp.start();
          return;
        }
      }
    }

    if (callId === "appear-zdog") {
      if (status === "enter") {
        zDogCtrl.inView = true;
        return;
      }
      zDogCtrl.inView = false;
    }

    if (callId === "appear-play-reel-btn") {
      if (!playReelButtonCtrl) {
        return;
      }
      if (status === "enter") {
        playReelButtonCtrl.inView = true;
        return;
      }
      playReelButtonCtrl.inView = false;
    }
  };

  const lightGallery = new LightBoxCtrl();
  lightGallery.launch();

  const menuCtrl = new MenuCtrl();
  menuCtrl.launch();

  const lottieCtrl = new LottieCtrl();
  lottieCtrl.launch();

  const countUpCtrl = new CountUpCtrl();
  countUpCtrl.launch();

  const playReelButtonCtrl = new PlayReelButtonCtrl();

  const locomotiveScrollCtrl = new LocomotiveScrollCtrl();
  locomotiveScrollCtrl.createScroll(onCallScroll);

  playReelButtonCtrl.launch(locomotiveScrollCtrl, rafCtrl);

  const drawerCtrl = new DrawerCtrl();
  drawerCtrl.applyToggleListeners();

  const paymentSwitchers = new PaymentSwitchersCtrl();
  paymentSwitchers.launch();

  const accordionCtrl = new AccordionCtrl();
  accordionCtrl.applyToggleListeners();

  const revealVideoHoverCtrl = new RevealVideoHoverCtrl();
  revealVideoHoverCtrl.launch();

  const updateDateHistoryCtrl = new UpdateDateHistoryCtrl();
  updateDateHistoryCtrl.update();

  const mouseFollower = new MouseFollower();

  const revealImageHoverCtrl = new RevealImageHoverCtrl();
  revealImageHoverCtrl.launch(rafCtrl);

  mouseFollower.launch();
  mouseFollower.play(rafCtrl);

  const zDogCtrl = new ZdogCtrl();
  zDogCtrl.createThinkIcon();
  zDogCtrl.createDesignIcon();
  zDogCtrl.createDevelopIcon();
  zDogCtrl.play(rafCtrl);

  const swupCtrl = new SwupCtrl();

  swupCtrl.onContentReplaced((e) => {
    menuCtrl.updateUrls(window.location.pathname);
    drawerCtrl.applyToggleListeners();
    paymentSwitchers.launch();
    accordionCtrl.applyToggleListeners();
    revealImageHoverCtrl.launch(rafCtrl);
    revealVideoHoverCtrl.launch();
    zDogCtrl.createThinkIcon();
    zDogCtrl.createDesignIcon();
    zDogCtrl.createDevelopIcon();
    zDogCtrl.play(rafCtrl);
    updateDateHistoryCtrl.update();
    mouseFollower.launch();
    mouseFollower.play(rafCtrl);
    introLoader.launchBannerAnimation();
    countUpCtrl.launch();
    lottieCtrl.launch();
    filterYearCtrl.launch();
    lightGallery.launch();
    //window.setTimeout(() => {
    locomotiveScrollCtrl.createScroll(onCallScroll);
    playReelButtonCtrl.launch(locomotiveScrollCtrl, rafCtrl);
    //}, 100);
  });

  swupCtrl.onWillReplaceContent((e) => {
    rafCtrl.clearCbs();
    accordionCtrl.removeCurrentToggleListeners();
    revealImageHoverCtrl.destroy();
    revealVideoHoverCtrl.destroy();
    zDogCtrl.destroy();
    mouseFollower.destroy();
    locomotiveScrollCtrl.lmScroll.scrollTo(0, { disableLerp: true });
    locomotiveScrollCtrl.destroyScroll();
    countUpCtrl.destroy();
    lottieCtrl.destroy();
    filterYearCtrl.destroy();
    lightGallery.destroy();
    playReelButtonCtrl.destroy();
    drawerCtrl.destroy();
    paymentSwitchers.destroy();
  });
});
