class MenuCtrl {
  constructor() {
    this.menu = undefined;
  }
  launch() {
    this.menu = document.querySelector(".menu");
  }
  updateUrls(currentPathName) {
    const scrollToElements = this.menu.querySelectorAll("[data-scroll-to]");
    for (let i = 0, j = scrollToElements.length; i < j; i++) {
      const element = scrollToElements[i];
      if (element.dataset.scrollToValidPath === currentPathName) {
        element.setAttribute("href", element.dataset.scrollToAnchor);
      } else {
        element.setAttribute("href", element.dataset.linkHref);
      }
    }
  }
}

export default MenuCtrl;
