class FilterYearCtrl {
  constructor() {
    this._onChangeYear = this.onChangeYear.bind(this);
    this.selectorYear = undefined;
    this.listHistory = undefined;
    this.projects = undefined;
    this.totalHeight = 0;
    this.transform = undefined;
    this.projectHeight = undefined;
  }
  launch() {
    this.selectorYear = document.querySelector("[data-year-selector]");
    if (!this.selectorYear) {
      return;
    }
    this.listHistory = document.querySelector(".history-wrapper");
    this.projects = this.listHistory.querySelectorAll(".conthist");
    this.projectHeight = this.projects[0].offsetHeight;
    let i = 0;
    this.transform =
      "transform" in document.body.style ? "transform" : "webkitTransform";
    while (++i < this.projects.length) {
      this.projects[i].style[this.transform] =
        "translateY(" + i * this.projectHeight + "px)";
    }
    this.totalHeight = this.projects.length * this.projectHeight;
    this.listHistory.style.height = `${this.totalHeight}px`;
    this.selectorYear.addEventListener("change", this._onChangeYear);
  }
  onChangeYear(e) {
    const year = e.target.value;
    let totalHeight = 0;
    this.projects.forEach((element, index) => {
      if (year === "all" || this.projects[index].dataset.year === year) {
        this.projects[index].classList.remove("hide");
        totalHeight += this.projectHeight;
      } else {
        this.projects[index].classList.add("hide");
      }
      var i = -1;
      var position = 0;
      while (++i < this.projects.length) {
        if (!this.projects[i].classList.contains("hide")) {
          this.projects[i].style[this.transform] = `translate3d(0px, ${
            position++ * this.projectHeight
          }px, 1px)`;
        }
      }
    });

    this.listHistory.style.height = `${totalHeight}px`;
    window.setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  destroy() {
    if (!this.selectorYear) {
      return;
    }
    this.selectorYear.removeEventListener("change", this._onChangeYear);
  }
}

export default FilterYearCtrl;
