class RafCtrl {
  constructor() {
    this.cbs = [];
    this.reqId = undefined;
  }
  launch() {
    this.update();
  }
  handleCb(cb) {
    this.cbs.push(cb);
  }
  update(time) {
    for (let i = 0, j = this.cbs.length; i < j; i++) {
      const cb = this.cbs[i];
      if (typeof cb === "function") {
        cb(time);
      }
    }
    this.reqId = window.requestAnimationFrame(this.update.bind(this));
  }
  clearCbs() {
    this.cbs = [];
  }
}

export default RafCtrl;
