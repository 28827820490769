export const headerAnimationData = [
  {
    label: "[data-gsap-intro='header-black-part']",
    from: { scaleX: 0 },
    to: {
      scaleX: 1,
      duration: 1.3,
      ease: "power3.out",
    },
  },
  {
    label: "[data-gsap-intro='header-white-part']",
    from: { scaleX: 0 },
    to: {
      scaleX: 1,
      duration: 1.3,
      ease: "power3.out",
    },
    position: "[data-gsap-intro='header-black-part']",
  },
  {
    label: "[data-gsap-intro='brand-claim']",
    from: { opacity: 0 },
    to: {
      opacity: 1,
      duration: 0.5,
    },
    position: "<+0.5",
  },
  {
    label: "[data-gsap-intro='header-mailto']",
    from: { opacity: 0 },
    to: {
      opacity: 1,
      duration: 0.5,
    },
    position: "<",
  },
  {
    label: "[data-gsap-intro='brand-wrapper']",
    from: { opacity: 0, translateY: -50 },
    to: {
      opacity: 1,
      translateY: 0,
      duration: 0.5,
    },
    position: ">[data-gsap-intro='header-black-part']",
  },
  {
    label: "[data-gsap-intro='say-hello']",
    from: { opacity: 0, translateX: 50, visibility: "hidden" },
    to: {
      opacity: 1,
      translateX: 0,
      visibility: "visible",
      duration: 0.5,
    },
    position: ">[data-gsap-intro='header-black-part']",
  },
];

export const bannerAnimationData = [
  {
    label: "[data-gsap-intro='banner-phrase-h1-1']",
    from: {
      opacity: 0,
      translateY: 10,
    },
    to: {
      opacity: 1,
      translateY: 0,
      duration: 0.5,
    },
    position: ">-1.3",
  },
  {
    label: "[data-gsap-intro='banner-phrase-h1-2']",
    from: {
      opacity: 0,
      translateY: 10,
    },
    to: {
      opacity: 1,
      translateY: 0,
      duration: 0.5,
      onComplete: () => {
        const secondPhraseH1 = document.querySelector(
          "[data-gsap-intro='banner-phrase-h1-1']"
        );
        const overlayBlue = document
          .querySelector("[data-gsap-intro='banner-phrase-h1-2']")
          .querySelector(".text-highlighted");
        overlayBlue.classList.add("active");
      },
    },
    position: ">-1",
  },
  {
    label: "[data-gsap-intro='reel-wrapper']",
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
      duration: 0.5,
    },
    position: ">-1",
  },
];
