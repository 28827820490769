import { TimelineMax } from "gsap";
import {
  headerAnimationData,
  bannerAnimationData,
} from "./gsap-data-animations/IntroHomeAnimationData";

class IntroLoader {
  constructor() {
    this.container = undefined;
    this.tl = new TimelineMax({ paused: true });
  }

  launch() {
    this.container = document.querySelector(".loader-screen");
    window.addEventListener("load", this.launchHomeIntroAnimation.bind(this));
  }

  launchBannerAnimation() {
    const isHome = document.querySelector(
      "[data-gsap-intro='banner-phrase-h1-1']"
    );
    if (!isHome) {
      return;
    }
    const updatedAnimationData = [...bannerAnimationData];
    delete updatedAnimationData[0].position;

    this.playAnimation(bannerAnimationData);
  }

  launchHomeIntroAnimation(e) {
    this.container.classList.add("ready");

    const isHome = document.querySelector(
      "[data-gsap-intro='banner-phrase-h1-1']"
    );

    const introAnimationData = [
      ...headerAnimationData,
      ...(isHome ? bannerAnimationData : []),
    ];
    this.playAnimation(introAnimationData);
  }

  playAnimation(animationData) {
    for (let i = 0, j = animationData.length; i < j; i++) {
      const animationProperties = animationData[i];
      this.tl.fromTo(
        animationProperties.label,
        animationProperties.from,
        animationProperties.to,
        animationProperties.position
      );
    }
    this.tl.play();
  }
}

export default IntroLoader;
