import Menu from "./menu";

class RevealImageHoverCtrl {
  constructor() {
    this.menu = undefined;
  }
  launch(rafCtrl) {
    const menuEl = document.querySelector(".highlighted-projects__wrapper");
    if (!menuEl) {
      return null;
    }
    this.menu = new Menu(menuEl, rafCtrl);
  }
  destroy() {
    if (!this.menu) {
      return;
    }
    this.menu.destroy();
    this.menu = null;
  }
}

export default RevealImageHoverCtrl;
