import { MathUtils } from "three";

class MouseFollower {
  constructor() {
    this.isDesktop = false;
    this.checkpoint = 1000;
    this.container = undefined;
    this.prevTime = 0;
    this.position = {
      x: 0,
      y: 0,
    };
    this.prevPosition = {
      x: 0,
      y: 0,
    };
    this.mousePos = {
      x: -100,
      y: -100,
    };
    this.reactiveElements = [];
    // this.rafId = undefined;
    this.followFactor = 8;
    this.scaleMoveFactor = 1;
    this.gui = undefined;
    // this.lmScrollCtrl = undefined;
    this._onMouseMove = this.onMouseMove.bind(this);
    this._onMouseEnterReact = this.onMouseEnterReact.bind(this);
    this._onMouseLeaveReact = this.onMouseLeaveReact.bind(this);
    this._onWindowResize = this.onWindowResize.bind(this);
  }

  launch(gui) {
    this.onWindowResize();
    const container = document.querySelector(".mouse-tracker");
    if (!container) {
      return;
    }
    if (gui) {
      this.gui = gui.addFolder("Mouse Tracker");
      this.prepareTweeks();
    }
    // if (lmScrollCtrl) {
    //   this.lmScrollCtrl = lmScrollCtrl;
    // }
    this.container = container;
    this.handleListeners();
  }

  prepareTweeks() {
    this.gui.add(this, "followFactor").min(0).max(20).step(0.001).name("delay");
    this.gui
      .add(this, "scaleMoveFactor")
      .min(-1)
      .max(3)
      .step(0.001)
      .name("scale on move");
  }

  handleListeners() {
    window.addEventListener("resize", this._onWindowResize);
    document.addEventListener("mousemove", this._onMouseMove);
    const reactiveElements = document.querySelectorAll(
      "[data-mouse-reactive], a"
    );
    for (let i = 0, j = reactiveElements.length; i < j; i++) {
      const reactiveElement = reactiveElements[i];
      reactiveElement.addEventListener("mouseenter", this._onMouseEnterReact);
      reactiveElement.addEventListener("mouseleave", this._onMouseLeaveReact);
    }
    this.reactiveElements = reactiveElements;
  }

  onWindowResize() {
    if (window.innerWidth >= this.checkpoint) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
  }

  onMouseMove(e) {
    this.mousePos.x = e.clientX;
    this.mousePos.y = e.clientY;
  }

  onMouseEnterReact(e) {
    this.container.classList.add("hovering");
  }

  onMouseLeaveReact(e) {
    this.container.classList.remove("hovering");
  }

  play(rafCtrl) {
    // if (!this.rafId) {
    //   this.updatePosition();
    // }
    rafCtrl.handleCb(this.updatePosition.bind(this));
  }

  updatePosition() {
    if (!this.isDesktop) {
      return;
    }
    const deltaVec = {
      x: this.mousePos.x - this.prevPosition.x,
      y: this.mousePos.y - this.prevPosition.y,
    };

    this.position = {
      x: deltaVec.x / this.followFactor + this.position.x,
      y: deltaVec.y / this.followFactor + this.position.y,
    };

    const deltaVecLength = Math.sqrt(
      Math.pow(deltaVec.x, 2) + Math.pow(deltaVec.y, 2)
    );

    // let scrollSmoothFactor = 0;

    // if (
    //   this.lmScrollCtrl &&
    //   this.lmScrollCtrl.lmScroll &&
    //   this.lmScrollCtrl.lmScroll.smooth
    // ) {
    //   scrollSmoothFactor = this.lmScrollCtrl.lmScroll.scroll.instance.scroll.y;
    // }

    // const normalizedDeltaVecLength = MathUtils.clamp(
    //   MathUtils.inverseLerp(1, 500, deltaVecLength),
    //   1,
    //   500
    // );

    const clampedDeltaVecLength = MathUtils.clamp(deltaVecLength, 0, 500);
    const normalizedDeltaVecLength = MathUtils.inverseLerp(
      0,
      500,
      clampedDeltaVecLength
    );

    const scaleOnMove = 1 + normalizedDeltaVecLength * this.scaleMoveFactor;

    this.container.style.transform = `translate3d(${this.position.x}px, ${this.position.y}px, 1px) scale(${scaleOnMove})`;
    // this.container.style.transform = `translate3d(${this.position.x}px, ${this.position.y}px, 1px)`;

    this.prevPosition = { ...this.position };
    // this.rafId = window.requestAnimationFrame(this.updatePosition.bind(this));
  }

  destroy() {
    // if (this.rafId) {
    //   window.cancelAnimationFrame(this.rafId);
    //   this.rafId = null;
    // }
    window.removeEventListener("resize", this._onWindowResize);
    document.removeEventListener("mousemove", this._onMouseMove);
    for (let i = 0, j = this.reactiveElements.length; i < j; i++) {
      const reactiveElement = this.reactiveElements[i];
      reactiveElement.removeEventListener(
        "mouseenter",
        this._onMouseEnterReact
      );
      reactiveElement.removeEventListener(
        "mouseleave",
        this._onMouseLeaveReact
      );
    }
    this.reactiveElements = [];
    this.container.classList.remove("hovering");
    this.container = undefined;
  }
}

export default MouseFollower;
